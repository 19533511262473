//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

// For Import QA Loader
import {data} from "../include/Loader";

function ModifyPracticeSetNameContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    // For Loader Modal
    const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);

    function openModalLoad() {
        setIsOpenLoad(true);
    }

    function afterOpenModalLoad() {
        subtitle.style.color = '#f00';
    }

    function closeModalLoad() {
        setIsOpenLoad(false);
    }
    // For Loader Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblod2={
        fontWeight: '700',
        fontSize: '0.8rem'
    };


    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        // fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        // fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'small',
        width: '12%'
    };

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let limit = 200;
    //let [limit,setLimit]=useState(3);

    let [load,setLoad]=useState(false);

    let [practicesetlistarr, setPracticesetlistarr]=useState([]);
    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState("");

    async function getPracticesetlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getpracticesetlist",fd);
        var data=resp.data;
        setPracticesetlistarr(data.obj);
        setCount(data.datacount);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));

        console.log("===>",pageCount);

        setLoader(false);
        
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [packageparentid,setPackageparentid]=useState("");

    // For Modal
    let [practicesetname,setPracticesetname]=useState("");
    let [practicesetid,setPracticesetid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal
    
    const handlePageClick = async (event) => {
        setLoader(true);
        let page=event.selected+1;
        setPageno(page);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getpracticesetlist",fd);
        var data=resp.data;
        setPracticesetlistarr(data.obj);
        setCount(data.datacount);

        // const total=data.datacount;
        // setPageCount(Math.ceil(total/limit));

        setLoader(false);
    };

    useEffect(()=>{
        getPracticesetlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    MODIFY PRACTICE SET LIST - TOTAL COUNT ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod}>Set ID</th>
                                    <th style={txtblod}>Set Name</th>
                                    <th style={txtblod}>QID Count</th>
                                    <th style={txtblod}>Shuffle</th>
                                    <th className='text-center' style={txtblod}>Allocate Package</th>
                                    <th className='text-center' style={txtblod}>Ready Set</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {practicesetlistarr.map((e)=>
                                <tr key={e.practiceset_slno}>
                                <td style={txtblod}>{e.practiceset_slno}</td>
                                <td style={txtblod2}>{e.practiceset_name}</td>
                                <td style={txtblod2}>{e.count_qid}</td>
                                <td className='text-center' style={txtblod2}>
                                {e.practiceexamsetselection_shuffle==0?
                                <td className='text-center' style={txtred}>NON SHUFFLE</td>
                                :e.practiceexamsetselection_shuffle==1?
                                <td className='text-center' style={txtgreen}>FULL SHUFFLE</td>
                                :
                                <td className='text-center' style={txtblue}>PARTIAL SHUFFLE</td>
                                }    
                                </td>
                                <td className='text-center' style={txtblod2}>
                                {e.practiceset_selection==0?
                                <td className='text-center' style={txtred}>UNALLOCATE</td>
                                :
                                <td className='text-center' style={txtgreen}>ALLOCATED</td>
                                }    
                                </td>

                                <td className='text-center' style={txtblod2}>
                                {e.examreadycount==0?
                                <td className='text-center' style={txtred}>NOT READY<br/>
                                <a role="button" onClick={async ()=>{
                                        // setLoad(true);
                                       
                                        var alertconfirm=window.confirm("Do you want to import question answer into this exam?");
                                        if (alertconfirm) {
                                            openModalLoad();
                                            var fd=new FormData();
                                            fd.append("practiceset_slno",e.practiceset_slno);
                                            var response=await axios.post("https://safalata.net/safalatalara/api/importquestionanswerintoset",fd);
                                            var data=response.data;
                                            // setLoad(false);
                                            closeModalLoad();
                                            getPracticesetlist();
                                        }


                                }}><i class="fas fa-random"></i>-</a></td>
                                :
                                <td className='text-center' style={txtgreen}>READY</td>
                                }    
                                </td>
                                
                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setPracticesetid(e.practiceset_slno);
                                        setPracticesetname(e.practiceset_name);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>




            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::PRACTICE SET MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setPracticesetname(ev.target.value);
                }} value={practicesetname}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("practicesetid",practicesetid);
                fd.append("practicesetname",practicesetname);
                var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getPracticesetlist();
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>

        <Modal
            isOpen={modalIsOpenLoad}
            onAfterOpen={afterOpenModalLoad}
            onRequestClose={closeModalLoad}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}
                    
                </div>
            </div>                        
            
            

        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default ModifyPracticeSetNameContent;